<template>
  <div>
    <vs-card>
      <div class="flex items-center justify-between h-16 px-4">
        <div class="flex items-center">
          <feather-icon
            icon="HashIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            المعرف : {{ order.id }}
          </p>
        </div>

        <div class="flex items-center">
          <feather-icon
            icon="ClipboardIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            نوع الطلب : {{ order.order_type }}
          </p>
        </div>

        <div class="flex items-center">
          <feather-icon
            icon="UserIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            الزبون : {{ order.user.first_name }}
          </p>
        </div>
      </div>
      <div
        class="flex items-center justify-around h-16 px-4 mt-4"
        v-if="order.message || order.rejection_reason"
      >
        <div
          class="flex items-center"
          v-if="order.message"
        >
          <feather-icon
            icon="FileTextIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            الملاحظات المرسلة للزبون: {{ order.message }}
          </p>
        </div>

        <div
          class="flex items-center"
          v-if="order.rejection_reason"
        >
          <feather-icon
            icon="XCircleIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            سبب رفض الطلب : {{ order.rejection_reason }}
          </p>
        </div>
      </div>
    </vs-card>

    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
    />

    <vs-popup
      title="معلومات المنتج"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 px-4">
        <div>
          <label>المعرّف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.id"
          />
        </div>

        <div>
          <label>الكمية :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.qty"
          />
        </div>

        <div>
          <label>اسم المنتج :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.sub_product_name"
          />
        </div>

        <div>
          <label>سعر المنتج :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.price"
          />
        </div>

        <div>
          <label>حالة الطلب :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.arabic_status"
          />
        </div>

        <div v-if="orderDetail.package_class">
          <label>فئة التغليف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.package_class.class"
          />
        </div>

        <div v-if="orderDetail.package_class">
          <label>سعر التغليف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.package_class.price"
          />
        </div>
        <div
          class="col-span-3"
          v-if="orderDetail.user_note"
        >
          <label>ملاحظات الزبون :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.user_note"
          />
        </div>

        <div
          class="col-span-3"
          v-if="orderDetail.message"
        >
          <label>ملاحظات مدير المحل :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="orderDetail.message"
          />
        </div>
      </div>


      <div class="flex justify-center items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup
      :title="this.statusAction"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form>
          <div class="vx-row mt-5 justify-center">
            <h3> سيتم تغيير حالة الطلب من</h3>
            <h3
              :style="{'color':fromStatusColor }"
              class="vx-col"
            >
              {{ fromStatus }}
            </h3>
            <h3> إلى </h3>
            <h3
              :style="{'color':toStatusColor }"
              class="vx-col"
            >
              {{ toStatus }}
            </h3>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full mb-2">
              <label>الرسالة</label>
              <vs-input
                placeholder="الرسالة"
                v-model="message"
                class="w-full"
              />
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit())"
              >
                إرسال
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </div>
</template>

<script>
import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell';
import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import AgIcon from "@/app/ecommerce/ecommerce-components/AgIcon";
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import 'quill/dist/quill.snow.css';
import utilities from "@/app/shared/utilities";

const order = RepositoryFactory.get('order');

export default {
  name: "OrderDetail",
  components: {
    AgTable
  },
  data() {
    return {
      isLoading: true,
      gridOptions: null,
      rowData: [],
      order: {
        id: '',
        order_type: '',
        user: {first_name: ''}
      },
      popupActiveDetails: false,
      popupActive: false,
      imageRequired: true,
      imagePreview: null,
      dialogTitle: String,
      statusAction: '',
      newStatus: '',
      fromStatus: '',
      fromStatusColor: '',
      toStatus: '',
      toStatusColor: '',
      orderDetail: {
        id: null,
        qty: '',
        user_note: '',
        status: '',
        arabic_status: '',
        uploaded_image_url: '',
        package_class: {
          class: '',
          price: ''
        },
      },
      orderDetailId: '',
      message: ''
    };
  },
  watch: {
    "$route.params.id"() {
      this.fetchAllOrderDetails();
    },
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          imagePreview: null,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'معرف الطلب',
          field: 'order_id',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'خيارات المنتج المطلوب',
          valueGetter:
            function getObject(params) {
              return params;
            },
          cellRendererFramework: AgIcon,
          cellRendererParams: {
            icon: 'ChevronsLeftIcon',
            secondIcon: 'XCircleIcon',
            click(valueGetter) {
              self.changeOrderDetailsStatus(valueGetter);
            },
            clickToReject(valueGetter) {
              self.changeOrderDetailsStatus(valueGetter, true);
            },
          }
        },
        {
          headerName: 'اسم المنتج',
          field: 'sub_product_name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'صورة المنتج',
          cellRendererFramework: AgTableImageCell,
          valueGetter: subProductImageGetter,
        },
        {
          headerName: 'حالة الطلب',
          field: 'arabic_status',
          filter: 'agTextColumnFilter',
          cellStyle: self.changeCellColor,
        },
        {
          headerName: 'الكمية',
          field: 'qty',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'سعر المنتج',
          field: 'price',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الصورة المرفقة',
          field: 'uploaded_image_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'فئة التغليف',
          field: 'package_class.class',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'سعر التغليف',
          field: 'package_class.price',
          filter: 'agTextColumnFilter',
          valueGetter: packagingPriceGetter
        },
        {
          headerName: 'ملاحظات الزبون',
          field: 'user_note',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'ملاحظات مدير المحل',
          field: 'message',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view']
          }
        },
      ];
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.orderDetail = record;
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    changeCellColor(params) {
      return {'color': utilities.setOrderStatusColor(params.node.data.status)};
    },
    changeOrderDetailsStatus(record, isRejected = false) {
      this.orderDetailId = record.data.id;
      let statusObject = utilities.changeOrderStatus(record.data, isRejected);
      this.statusAction = statusObject.statusAction;
      this.newStatus = statusObject.newStatus;
      this.fromStatus = statusObject.fromStatus;
      this.toStatus = statusObject.toStatus;
      this.fromStatusColor = statusObject.fromStatusColor;
      this.toStatusColor = statusObject.toStatusColor;
      if (this.newStatus != null) {
        this.openPopup();
      }
    },
    onSubmit() {
      this.isLoading = true;
      order.changeOrderDetailsStatus(this.orderDetailId, this.newStatus, this.message).then((response) => {
        let index = this.rowData.map((m) => {
          return m.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.popupActive = false;
        this.isLoading = false;
      });
    },

    // get order details from the order
    fetchAllOrderDetails() {
      order.fetchOrderById(this.$route.params.id).then((data) => {
        this.order = data;
        this.rowData = data.orderDetails;
        this.isLoading = false;
      });
    },
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.gridOptions.defaultColDef = {resizable: true,};
    this.fetchAllOrderDetails();

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
window.packagingPriceGetter = function (params) {
  if (params.data.package_price)
    return params.data.package_price * params.data.qty;
};
window.subProductImageGetter = function (params) {
    return params.data.sub_product.sub_product_images[0].image_url;
};
</script>

<style scoped>
.icon_color {
  color: #4B364E !important;
}

.brief_color {
  color: #888888;
  font-size: 18px;
  font-weight: 600;
}
</style>
